<template>
  <div class="custom-contianer">
    <div class="contents-header">
      <div class="breadcrumb-container">
        <div class="breadcrumb">
          <div class="mt-1 row text-center">
            <div class="col-12 text-start">
              <router-link :to="{ name: 'Record' }" class="ashen-link">{{
                  $t("record.default.pageTitle")
                }}
              </router-link>
              <i class="fa fa-angle-right m-l-10 m-r-5"></i>
              <router-link :to="linkUserRecord" class="ashen-link">{{
                  $t("record.recordList.healthRecordList")
                }}
              </router-link>
              <i class="fas fa-angle-right form-control-color"></i> {{
                $t("record.recordDetails.healthRecordDetails")
              }}
            </div>
          </div>
        </div>
      </div>

      <div class="next-prev-container">
        <div class="next-prev font-13 m-t-20 m-b-25">
          <div class="mt-1 row text-center">
            <div class="col text-start text-clamp-1">
              <router-link v-if="prevDateString" :to="linkPrevDate" class="ashen-link">
                <i class="fas fa-angle-left color-88 font-16"></i>
                <span class="ml-3 mx-2 font-weight-bold">{{ prevDateString }} <span
                    v-html="showDay(prevDateString)"></span></span>
              </router-link>
            </div>
            <div class="col text-end d-flex next-tournament">
              <router-link v-if="nextDateString" :to="linkNextDate" class="ashen-link text-clamp-1">
                  <span class="mr-3 mx-1 font-weight-bold">{{ nextDateString }} <span
                      v-html="showDay(nextDateString)"></span></span>
              </router-link>
              <i v-if="nextDateString" class="fas fa-angle-right mt-1 color-88 font-16"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-4">
      <div class="card-header color-33 bg-transparent">
        <img src="../assets/images/calendar-default.svg"/>
        <span class="p-l-10 header-date" v-html="date + ' ' +  showDay(date)"></span>
        <span class="m-l-20 header-status d-inline-block"
              v-html="showHealthStatus(userRecordDetails.status)">
        </span>
      </div>
      <div class="card-body pl-3">
        <div class="form-holder">
          <div class="row data-container">
            <!--
              <div class="col left-data">
                <label>{{ $t("record.recordDetails.labelBodyTemperature") }}</label>
                <input v-model="userRecordDetails.bodyTemp" class="form-control mt-1 filter-select font-16"
                       placeholder=""
                       readonly
                       type="text">
                <template v-if="userRecordDetails.answers">
                  <label>{{ $t("record.recordDetails.labelFeverExceedsHeat") }}</label>
                  <input v-model="userRecordDetails.answers[0].value" class="form-control mt-1 filter-select font-16"
                         placeholder="" readonly
                         type="text">
                  <label>{{ $t("record.recordDetails.labelColdSymptoms") }}</label>
                  <input v-model="userRecordDetails.answers[1].value" class="form-control mt-1 filter-select font-16"
                         placeholder="" readonly
                         type="text">
                  <label>{{ $t("record.recordDetails.labelDullness") }}</label>
                  <input v-model="userRecordDetails.answers[2].value" class="form-control mt-1 filter-select font-16"
                         placeholder="" readonly
                         type="text">
                  <label>{{ $t("record.recordDetails.labelAbnormalSense") }}</label>
                  <input v-model="userRecordDetails.answers[3].value" class="form-control mt-1 filter-select font-16"
                         placeholder="" readonly
                         type="text">
                  <label>{{ $t("record.recordDetails.labelFeelingHeavy") }}</label>
                  <input v-model="userRecordDetails.answers[4].value" class="form-control mt-1 filter-select font-16"
                         placeholder="" readonly
                         type="text">
                  <label>{{ $t("record.recordDetails.labelPresenceAbsenceContact") }}</label>
                  <input v-model="userRecordDetails.answers[5].value" class="form-control mt-1 filter-select font-16"
                         placeholder="" readonly
                         type="text">
                  <label>{{ $t("record.recordDetails.labelCloseAcquaintance") }}</label>
                  <input v-model="userRecordDetails.answers[6].value" class="form-control mt-1 filter-select font-16"
                         placeholder="" readonly
                         type="text">
                  <label>{{ $t("record.recordDetails.immigrationRestrictions") }}</label>
                  <input v-model="userRecordDetails.answers[7].value" class="form-control mt-1 filter-select font-16"
                         placeholder="" readonly
                         type="text">
                  <label>{{ $t("inputHealthCondition.default.pcrResutIsPositive") }}</label>
                  <input v-model="userRecordDetails.answers[8].value" class="form-control mt-1 filter-select font-16"
                         placeholder="" readonly
                         type="text">

                </template>
              </div>
              -->
              <div class="col right-data">
                <label>{{ $t("inputHealthCondition.default.tiredness") }}</label>
                <input :value="getHealthCondition(userRecordDetails.heatStrokeQuestionTiredness)"
                       class="form-control mt-1 filter-select font-16"
                       placeholder="" readonly
                       type="text">
                <label>{{ $t("inputHealthCondition.default.appetite") }}</label>
                <input :value="getHealthCondition(userRecordDetails.heatStrokeQuestionAppetite)"
                       class="form-control mt-1 filter-select font-16"
                       placeholder="" readonly
                       type="text">
                <label>{{ $t("inputHealthCondition.default.sleep") }}</label>
                <input :value="getHealthCondition(userRecordDetails.heatStrokeQuestionSleep)"
                       class="form-control mt-1 filter-select font-16"
                       placeholder="" readonly
                       type="text">
                <label>{{ $t("inputHealthCondition.default.sweat") }}</label>
                <input :value="getHealthCondition(userRecordDetails.heatStrokeQuestionSweat, true)"
                       class="form-control mt-1 filter-select font-16"
                       placeholder="" readonly
                       type="text">
                <label>{{ $t("record.recordDetails.urineColor") }}</label>
                <input :value="getPeeColor(userRecordDetails.peeColor)"
                       class="form-control mt-1 filter-select font-16"
                       placeholder="" readonly
                       type="text">

                <label>{{ $t("record.recordDetails.heatstrokeHistory") }}</label>
                <input :value="getAns(userRecordDetails.everHadHeatStroke)"
                       class="form-control mt-1 filter-select font-16"
                       placeholder="" readonly
                       type="text">

                <label>{{ $t("record.recordDetails.score") }}</label>
                <input :value="getHeatStrokeScore(userRecordDetails.heatStrokeScore)"
                       class="form-control mt-1 filter-select font-16"
                       placeholder="" readonly
                       type="text">
              </div>
            </div>
          <div class="row data-container">
            <div class="col mb-5 right-data">
              <label>{{ $t("record.recordDetails.concerns") }}</label>
              <textarea class="form-control" cols="50" readonly rows="10">
                 {{ remarksString }}
               </textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import Common from '../assets/jsipclient/common'
import Helper from '../assets/jsipclient/helper'
import {mapGetters} from 'vuex'

export default {
  name: "UserRecordDetail",
  components: {},
  props: {
    tournamentId: {type: [Number, String], default: null},
    userId: {type: String, default: null},
  },
  data() {
    return {
      userRecordDetails: {},
      prevDate: '',
      nextDate: '',
      approval: true,
      allCheck: false,
      loading: false,
      // isSetOngoingTournament: false,
      // isHeatStrokeVisible: false,
      // isOngoingTournament: false
    }
  },
  mounted() {
    this.getTournamentRecordDetails()
  },
  computed: {
    ...mapGetters(['config']),
    date() {
      return this.$route.query.date
    },
    prevDateString() {
      return this.prevDate === null ? '' : Common.dateFormatConvert(this.prevDate)
    },
    nextDateString() {
      return this.nextDate === null ? '' : Common.dateFormatConvert(this.nextDate)
    },
    linkPrevDate() {
      return {
        name: "UserRecordDetail",
        params: {
          tournamentId: Number(this.tournamentId),
          userId: this.config.userId,
        },
        query: {
          date: this.prevDate
        }
      }
    },
    linkNextDate() {
      return {
        name: "UserRecordDetail",
        params: {
          tournamentId: Number(this.tournamentId),
          userId: this.config.userId,
        },
        query: {
          date: this.nextDate
        }
      }
    },
    linkUserRecord() {
      return {
        name: "UserRecord",
        params: {
          tournamentId: this.tournamentId,
          userId: this.userId,
        }
      }
    },
    remarksString() {
      if (this.userRecordDetails.healthStatus === 3) {
        return ""
      }
      return this.userRecordDetails.remarks
    }
  },
  watch: {
    async $route(to, from) {
      // Called from back or next
      await this.getTournamentRecordDetails()
    }
  },
  methods: {
    showHealthStatus(healthStatus) {
      healthStatus = healthStatus === -1 ? 2 :
          healthStatus === 1 ? 5 :
              healthStatus === 0 ? 3 : healthStatus
      var statusText = ""
      switch (healthStatus) {
        case 2:
          statusText = this.$t("record.recordDetails.abnormality")
          break;
        case 3:
          statusText = this.$t("record.recordDetails.notEntered")
          break;
        case 5:
          statusText = this.$t("record.recordDetails.noAbnormality")
          break;
        default:
          break;
      }

      return Helper.showHealthStatus(healthStatus, '', statusText)
    },
    allCheckApprove(allCheck) {
      this.allCheck = allCheck
    },
    backToRecord() {
      this.$router.push({name: 'Record', params: {defaultResponse: this.defaultResponse}})
    },
    showDay(date) {
      return Helper.showDay(date, this.$i18n.locale)
    },

    // async getOngoingTournament() {
    //   this.isSetOngoingTournament = false
    //   try {
    //     const api = new IpApi.UserApi(Helper.apiConfig(this.config.token))
    //
    //     const tournament = await api.usersUserIdTournamentsTournamentIdGetAsync(
    //         this.config.userId,
    //         this.tournamentId
    //     )
    //     if (tournament != null) {
    //       this.isSetOngoingTournament = true
    //       this.isOngoingTournament = tournament.status === 2
    //     }
    //
    //   } catch (error) {
    //     const isTokenError = Common.isTokenError(error.response)
    //     if (isTokenError) {
    //       this.$router.push({name: 'Login'});
    //     } else {
    //       Common.handleAPIError(error)
    //     }
    //   }
    // },
    async getTournamentRecordDetails() {

      if (!this.config.userId || !this.tournamentId || !this.$route.query.date) {
        return
      }

      this.loading = true
      try {
        // this.getOngoingTournament()
        const api = new IpApi.UserApi(Helper.apiConfig(this.config.token))
        this.userRecordDetails = await api.usersUserIdRecordsDetailGetAsync(
            this.config.userId,
            this.tournamentId,
            this.$route.query.date,
        )

        if (this.userRecordDetails != null) {
          this.prevDate = this.userRecordDetails.prevDate
          this.nextDate = this.userRecordDetails.nextDate
          this.loading = false
          // this.isHeatStrokeVisible = this.userRecordDetails.everHadHeatStroke !== null
          // this.loading = !this.isSetOngoingTournament
        }

        if (this.userRecordDetails.bodyTemp === '0.0') {
          this.userRecordDetails.bodyTemp = '— —';
        }
      } catch (e) {
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    getHealthCondition(no, isSweat = false) {
      switch (no) {
        case 5:
          if (isSweat) {
            return this.$t('inputHealthCondition.default.veryBadForSweat')
          } else {
            return this.$t('inputHealthCondition.default.veryBad')
          }
        case 4:
          return this.$t('inputHealthCondition.default.bad')
        case 3:
          return this.$t('inputHealthCondition.default.averageUsually')
        case 2:
          return this.$t('inputHealthCondition.default.good')
        case 1:
          return this.$t('inputHealthCondition.default.veryGood')
        default:
          return ''
      }
    },
    getPeeColor(no) {
      switch (no) {
        case 1:
          return this.$t('inputHealthCondition.default.light')
        case 3:
          return this.$t('inputHealthCondition.default.average')
        case 5:
          return this.$t('inputHealthCondition.default.dark')
        case 7:
          return this.$t('inputHealthCondition.default.veryDark')
        default:
          return ''
      }
    },
    getAns(no) {
      switch (no) {
        case 1:
          return this.$t('inputHealthCondition.default.ok')
        case 2:
          return this.$t('inputHealthCondition.default.no')
        default:
          return ''
      }
    },
    getHeatStrokeScore(score) {
      if (score === null) {
        return ''
      }
      return score + this.$t('record.recordDetails.unit')
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-contianer {
  padding: 0 22px;
}

img {
  margin-bottom: 3px !important;
}

.form-control {
  background: #EFF2F7 !important;
}

.content_wrapper {
  padding: 0 32px;
}

.content {
  top: unset !important;
}

.nvlink-container {
  border-bottom: 1px solid #e2e2e2;
}

a {
  color: #333333;
}

.filter-select {
  font-size: 13px;
  height: 48px !important;
  line-height: 13px;
  background: #FAF7F9;
  color: #333333;
}

label {
  color: #333333;
}

.form-holder {
  padding: 0 80px;
}

.pd {
  padding: 5px 0;
}

textarea {
  background: #EFF2F7 !important;
  color: #333333;
  min-height: 207px;
  font-size: 13px;
}

.custom_btn {
  width: 350px;
}

.bg_black {
  background: #333333 0% 0% no-repeat padding-box;
}

.custom-contianer {
  label {
    padding: 17px 0 10px 0;

    &.pd {
      padding: 20px 0;
    }
  }

  .badge-calendar-check {
    background-color: #F0FFEA;
    color: #7EC100;
  }

  .badge-times-circle {
    background-color: #FFEAEB;
    color: #C1001B;
  }

  @media only screen and (max-width: 576px) {
    .card-header {
      display: inline-block !important;
      padding-right: .5rem !important;
      padding-left: .5rem !important;
    }
    .header-date {
      padding-right: .5rem !important;
      padding-left: .5rem !important;
    }
    .header-status {
      float: right !important;
      margin-left: 0px !important;
    }
    .form-holder {
      padding: 0px;
    }

    .data-container {
      flex-direction: column;
    }

    .right-data {
      width: 100% !important;
    }

    .left-data {
      width: 100% !important;
    }

  }
}
</style>
